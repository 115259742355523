import axios from "axios";
import Cookies from "js-cookie";
import { NavigateFunction } from "react-router-dom";
import { back_end_url, cookie_login_name, cookie_login_value, cookie_muffin_name, FFF_LOGIN, isLocal } from "../../app/constants";

type cookieProps = {
  nom: string,
  path: string,
  domain: string,
  value: string,
}

export function goToExternalPage(link: string | undefined) {
  if (link)
    window.location.assign(link);
};

export function openNewTabWithLink(link: string | undefined, cookies?: cookieProps[]) {
  if (link) {
    if (cookies) {
      cookies.forEach((cookie: cookieProps) => {
        Cookies.set(cookie.nom, cookie.value, { path: cookie.path, domain: cookie.domain });
        // console.log(cookie.nom);
      })
    }
    window.open(link, "_blank");
  }
  else window.open();
}

export function isObjectEmpty(object: any) {
  return (Object.keys(object).length === 0);
}

export function isArrayEmpty(arr: Array<any>) {
  return (arr.length === 0);
}

/** In Prod, check if muffin cookie exists, else go to https://sso-pre.fff.fr/oauth/v2/auth.
 * In Local, check if login cookie exists, else navigate to loginPage ('/')*/
export function cookieCheck(navigate: any) {
  if (isLocal && !Cookies.get(cookie_login_name)) navigate('/');
  else if (!isLocal && !Cookies.get(cookie_muffin_name)) goToExternalPage(FFF_LOGIN);
}

/** In Prod, check if muffin cookie exists, else logout.
 * In Local, check if login cookie exists, else logout*/
export function securityCheck(navigate: any) {
  if (isLocal && !Cookies.get(cookie_login_name)) logOut(navigate);
  else if (!isLocal && !Cookies.get(cookie_muffin_name)) logOut(navigate);
}

export function logOut(navigate: NavigateFunction) {
  localStorage.removeItem('user');
  localStorage.removeItem('rolesRef');
  localStorage.removeItem('univers');
  localStorage.removeItem('actualite');
  localStorage.removeItem('f2000');
  if (isLocal) {
    Cookies.remove(cookie_login_name);
    navigate('/');
  }
  else {
    axios.get(`${back_end_url}/disconnect`, { withCredentials: true }).then((response: any) => {
      // test si déjà déconnecté du SSO
      if (!Cookies.get('fff_sso_keep_login')) {
        goToExternalPage(FFF_LOGIN)
      } else {
        goToExternalPage(response.data);
      }
    })
  }
}

export function logIn(navigate: NavigateFunction) {
  Cookies.set(cookie_login_name, cookie_login_value);
  Promise.all([
    axios.get(`${back_end_url}/session`, { withCredentials: true }),
    axios.get(`${back_end_url}/referentiel/roles`, { withCredentials: true }),
    axios.get(`${back_end_url}/f2000/tuiles`, { withCredentials: true })
  ]).then((response: any) => {
    localStorage.setItem('user', JSON.stringify(response[0].data));
    localStorage.setItem('rolesRef', JSON.stringify(response[1].data));
    localStorage.setItem('f2000', JSON.stringify(response[2].data));
    navigate('/dashboard', {
      state: {
        user: response[0].data,
        rolesRef: response[1].data,
        f2000: response[2].data,
      }
    })

  }).catch((error: any) => {
    if (error.response.status === 401)
      logOut(navigate)
  });
}
